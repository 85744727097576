export const reviews = [
	{
		review:
			'A very helpful app to get a collective view of all the vendors visiting the society',
		name: 'Manvi Gupta'
	},
	{
		review: 'Finally a non hanging app to order my veggies from NGR Fresh',
		name: 'Himani Bansal'
	},
	{
		review:
			'Very good platform to connect with apartment and nearby vendors.. great app!',
		name: 'Priyanka Goyal'
	},
	{
		review:
			'This is a pretty neat app, works as intended and very easy to navigate and use. The payment integration is very good and has many options. The delivery of the items was on time and was handled with all covid precautions necessary. I would strongly suggest using it.',
		name: 'Kishore Nekkalapudi'
	},
	{
		review:
			'One stop shop for all your needs, a finest platform for the buyers to buy everything they need under one app. It will surely really save me lot of time and effort of filling up manual Google forms and excel.',
		name: 'Rakesh Gupta'
	},
	{
		review:
			'Nice app, a good way to get things delivered to your apartment from your favorites local merchants without any hassel. Categorizations of products helped to order right product without searching through entire list of products.',
		name: 'Punam Poddar'
	},
	{
		review:
			'Great and simplistic design. Easy to navigate. All vendors at one place helps to declutter. Keep up the good work',
		name: 'Rajesh Durkhure'
	},
	{
		review:
			'Very clean interface. Much better than ordering stuff on whatsapp. Well thought through features such as asking just the flat number if apartment is already selected. Would love to see more merchants and would be using it on daily basis. Great work, team Botiga!!',
		name: 'Ravi Singla'
	},
	{
		review:
			'I prefer buying groceries, veggies etc from sellers based in my society as I can trust them on quality but big problem I face is to discover who is selling what then get yourself added to their whatsApp/Telegram groups and keep track of daily menu I always wished for an app which do all this for me. Now, I have one. :)',
		name: 'Vishesh Sethi'
	}
]
